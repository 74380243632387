<template>
  <div>
    </div>
</template>
<script>
import firebase from '../service/firebase'
export default {
  props: ['currentToken'],
  data () {
    return {
      hasServiceWorker: false,
      userToken:"",
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize () {
      if (!('serviceWorker' in navigator)) {
        // console.warn('serviceWorker not working')
        return
      }
      if (!('PushManager' in window)) {
        // console.warn('PushManager not working')
        return
      }
      this.hasServiceWorker = true
    },
    askForPermission () {
      if (!this.hasServiceWorker) {
        return
      }

      firebase.messaging.getToken({ vapidKey: 'BIQyizZOTAA1z6tvyTmYryqL14gwHU-HMDzDCOP_acdShZpXr1VADGahdetknl35EvvqrMYJ8gwvbiqksDZpcCo' })
        .then((currentToken) => {
        if (currentToken) {
            //console.log('client token', currentToken)
            // let userTokenDevice = JSON.stringify({ 
            //                     userDeviceToken: currentToken,
            //                     });
            this.axios.post('/updateRestaurantEmpDeviceToken',
              {userTokenDevice:currentToken,restID:this.$storageData.profile.restID,restBranchID:this.$storageData.profile.restBranchID,
              emailID:this.$storageData.email,empID:this.$storageData.profile.pid}
            ).then((res)=>{
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                  // device token inserted successfully
                }
                else
                {
                  console.log(res.data.message);
                }
                }).catch((error) => {
                    console.log(error.response.data.message);
                  });
        } // currentToken
        //  else {
        //     // console.log('No registration token available. Request permission to generate one.');
        // }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        })
    }
  }
}
</script>