<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PushNotification from '@/components/PushNotification'
import api from '@/api/api';
Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
import VueSession from 'vue-session';
// import store from "@/state/store";
Vue.use(VueSession);


/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
    components: {
  
    PushNotification
  },

  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: "sds",
      tryingToLogIn: false,
      isAuthError: false,
      
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    if(localStorage.getItem("vuex")) {
      localStorage.removeItem("vuex");
    }
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
                                                                      
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        // const requestOptions = {
        //     method: 'POST',
        //    // headers: { 'Content-Type': 'application/json' },
        //     body: { email, password }
        // };

          if (email && password) {

            await this.$store.dispatch('login',{
              path:'restaurant-login',
              emailID:email,
              password:password
            })
                .then(()=>{
                  this.enableNotifications();
                  if(this.$store.getters.loggedRole == "restaurant"){
                    this.$router.push('/dashboard/restaurantDashboard');
                  }

                }).catch((error)=>{
                  this.$swal({
                    text: error.response.data.message,
                    icon: "error",
                  });
                });

             // let formData = new FormData();
             //  formData.append('emailID', email);
             //  formData.append('password', password);
             //
             //      this.axios.post('restaurantLogin', formData)
             //
             //      .then((result)=>{
             //        let status = result.data.status;
             //       // console.log(result.data.user);
             //        if(status==200){
             //           //  console.log(result.data.user);
             //                if (result.data.user) {
             //
             //                  // this.$session.start();
             //                   // this.$session.set('token', JSON.stringify(result.data.token));
             //
             //            // store user details and jwt token in local storage to keep user logged in between page refreshes
             //                     localStorage.setItem('user', JSON.stringify(result.data.user));
             //                     localStorage.setItem('loginType',JSON.stringify(result.data.user.loginType));
             //                     //sessionStorage.setItem("authUser", JSON.stringify(result.data.user));
             //                    //commit('loginSuccess', user);
             //                    ///router.push('/');
             //
             //                        this.enableNotifications();  // noti popup
             //                      // this.$router.push('/dashboard/restaurantDashboard');
             //                      this.$router.push(result.data.user.defaultUrl);
             //                }
             //        }
             //        else{
             //            this.isAuthError = true;
             //            this.authError = result.data.message;
             //        }
             //
             //
             //
             //      })
             //          .catch(function (error) {
             //           alert(error);
             //
             //        });
          }
      }
    },

      ///////////// push noti permission ////////////////////
	enableNotifications () {
      this.$refs.pushNotification.askForPermission()
    },
    onUpdateToken (newToken) {
      this.userToken = newToken
      // send token to the server
      api.update_token(this.userProfile, this.userToken)
    },
    onNewMessage (message) {
      var snackbarContainer = document.querySelector('#snackbar-message')
      var data = {
        message: message.notification.title + ': ' + message.notification.body,
        timeout: 10000,
        actionText: 'OK'
      }
      snackbarContainer.MaterialSnackbar.showSnackbar(data)
    },
	///////////// push noti permission ////////////////////

    
  },
};
</script>

<template>

  <div>
  
  <push-notification
        ref="pushNotification"
        :currentToken="userToken"
        @update-token="onUpdateToken"
        @new-message="onNewMessage" />

    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="60"
                  class="logo logo-dark" style="object-fit:cover;"
                />
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="60"
                  class="logo logo-light"
                  style="object-fit:cover;"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary" style="color: #F3766A;">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to CloudKitch.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Enter email"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <router-link
                          to="/auth/recoverpwd/2"
                          class="text-muted"
                          >Forgot password?</router-link
                        >
                      </div>
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>
                     <div class="mb-4">
                                <!-- <input class="form-check-input" type="checkbox" id="autoSizingCheck3" v-model="isTncAgreed"> -->
                                <label class="form-check-label" for="autoSizingCheck3" style="margin-left: 5px;">
                                  By continuing "you  agree to terms and conditions"
                                </label>
                    </div>
    
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm" style="background-color: #FFC60C;color: #212121;border: rgb(255, 198, 12);"
                        >Log In</b-button
                      >
                    </div>



                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} CloudKitch Pvt Ltd. 
              </p>
              <!-- Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Themesbrand -->
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.authentication-bg {
    background-color: rgb(216 226 245 / 25%)  !important;
}
</style>
