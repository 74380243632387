import firebase from 'firebase'

var config = {
  apiKey: "AIzaSyBaw9OVW2HQxGui7GeWRJijeBZ9c6tuRto",
  authDomain: "cloudkitch-pusher.firebaseapp.com",
  projectId: "cloudkitch-pusher",
  storageBucket: "cloudkitch-pusher.appspot.com",
  messagingSenderId: "735360015564",
  appId: "1:735360015564:web:cc889c51d59ef1978b3669",
  measurementId: "G-3H17BR6P1J"
}

firebase.initializeApp(config)

export default {
  // messaging: firebase.messaging()
  messaging : firebase.messaging.isSupported() ? firebase.messaging() : null
}